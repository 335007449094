.login_logo {
    height: 70px;
    margin: 30px auto;
    margin-top: 0px;
}

.login_icon {
    height: 16px;
    margin: auto;
    position: absolute;
    margin: 9px;
    top: 0;
    bottom: 0;
}

.centercontainer {
    width: 20rem;
    z-index: 1;
    padding: 10px;
}

.login_spin i.ant-spin-dot-item {
    background-color: #fff;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.pincode-input-text {
    border-bottom: 1px solid #818181 !important;
    color: #494949;
}