::-webkit-calendar-picker-indicator {
    margin-left: 0px;
    padding-left: 200px;
}

.cron .cron_builder .well {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cron .cron_builder {
    border: 0;
}

.well.well-small.row {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.cron input[type="radio"] {
    cursor: pointer;
}

.cron input[type=checkbox]:checked {
    background-color: #1890ff !important;
    outline: none;
    width: 15px !important;
    height: 15px !important;
}

.cron input[type=checkbox] {
    width: 15px !important;
    height: 15px !important;
    cursor: pointer;
}

.cron .cron-builder-bg {
    background-color: #1890ff;
}

.ant-cron {
    width: 100%;
    justify-content: space-around;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.ant-cron div span {
    padding-right: 10px;
    padding: 0px;
}

.ant-cron > div:last-child > div {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
}

.ant-cron > div:last-child > div:nth-child(2) > span::before {
    content: 'min';
}

.ant-cron > div:last-child > button{
    align-self: flex-end;
}

.ant-cron div div {
    display: flex;
    flex-grow: 1;
}

.react-js-cron-field.react-js-cron-period.ant-cron-field.ant-cron-period {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 5px;
}

.react-js-cron-field.react-js-cron-months.ant-cron-field.ant-cron-months {
    width: 30%;
    padding-right: 5px;
}

.react-js-cron-field.react-js-cron-month-days.ant-cron-field.ant-cron-month-days {
    width: 40%;
    padding-right: 5px;
}

.react-js-cron-field.react-js-cron-week-days.ant-cron-field.ant-cron-week-days {
    width: 40%;
    padding-right: 5px;
}

.ant-cron>div:last-child {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}