.calendar-switch button.ant-switch {
    background-color: #1890ff;
    background-image: none;
}

div.fc-event-time {
    display: none;
}

.fc-daygrid-event {
    display: flex;
    white-space: normal;
    align-items: flex-start;
    justify-content: center;
    padding: 5px 5px
}

.fc-daygrid-event-dot {
    margin: 7px 4px;
}