.pop_over_driver {
    position: absolute;
    top: -200%;
    left: -175px;
    width: 170px;
}

.pop_over_driver_mobile {
    position: absolute;
    top: 0;
    left: -160px;
    width: 170px;
}

div.emptyContainer .ant-empty-image {
    height: 20px !important;
}

div.emptyContainer .ant-empty.ant-empty-normal {
    margin: 5px 0px;
}