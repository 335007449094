/* .logo {
    width: 150px;
} */

li.ant-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.sidebar {
    width:250px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: #fff; 
    transition: width 300ms;
    border-right: 2px solid #DDE2FF;
} 

.sidebar-brand {
    height: 160px;
    padding: 1rem;
    color: #fff;
}

.sidebar-brand {
    display: inline-block;
    padding: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}


.sidebar-menu {
    margin-top: 0.5rem;
} 

.sidebar-menu img {
    height: 1.2rem;
    margin: auto 0;
}  

.side-menu span {
    padding:0 12px;
}

.side-menu img {
    margin-top: auto;
    margin-bottom: auto;
}  

.sidebar-menu ul > li > a {
    padding: 0.7rem 1rem;
    display: block;
    color: #181818;
    font-size: 1rem; 
    border-left: 4px solid #fff; 
}

.link-active, .sidebar-menu ul > li > a:hover {  
    color: #1890ff !important; 
    background: rgba(24, 144, 255, 0.1);
    border-left: 4px solid #1890ff !important; 
}  

.sidebar-menu a span:first-child {
    font-size: 1rem;
    padding-right: 1rem;
    color: #191919;
    padding-left: 19px;
    white-space: nowrap;
}  

.sidebar-menu ul ul.submenu > li > a.link-active span{
    color: #1890ff !important;
}

.sidebar-menu a span:first-child:hover {
    color: #1890ff !important; 
}

#nav-toggle:checked + .sidebar .sidebar-menu img {
    height: 1.5rem;
    margin: auto 0.5rem;
}

#nav-toggle:checked + .sidebar {
    width:70px;
}

#nav-toggle:checked + .sidebar .sidebar-brand,
#nav-toggle:checked + .sidebar li{
    /* padding-left: 1.2rem; */
    text-align: center;
}

#nav-toggle:checked + .sidebar .sidebar-brand {
    height: 100px;
}

#nav-toggle:checked + .sidebar .sidebar-brand span {
    padding: 0;
} 

#nav-toggle:checked + .sidebar li a{
    padding-left: 0rem; 
} 

#nav-toggle:checked + .sidebar li  span{
    padding-right: 1rem;
}

#nav-toggle:checked + .sidebar li a span:last-child{
    display: none;
}

#nav-toggle:checked + .sidebar .sidebar-brand{
    margin-top: 1.5rem;
    padding: 0rem 0.25rem;
}

/* #nav-toggle:checked + .sidebar .sidebar-brand img.logo:last-child{
    visibility: hidden;
} */

.logo{
    visibility: visible;
}


/* #nav-toggle:checked + .sidebar .sidebar-brand img.mini:last-child{
    visibility: visible;
} */

.mini {
    visibility: hidden;
    /* height: 100%; */
}

#nav-toggle:checked ~ .main-content{
    margin-left:70px;
}

#nav-toggle:checked ~ .main-content header{
    width: calc(100% - 70px); 
}

#nav-toggle {
    display: none
}

.popover-menu{
    display: none;
}


@media only screen and (max-width: 1200px) {
    .sidebar {
        width:70px;
    } 
    
    .sidebar li a{
        padding-left: 0rem;
    }
    
    .sidebar li  span{
        padding-right: 1rem;
    }
    
    .sidebar li a span:last-child{
        display: none;
    }

    .sidebar-menu {
        display: none;
    }

    .popover-menu{
        display: block;
    }
    
    .sidebar .sidebar-brand{
        padding: .5rem .5rem;
    }
    
    /* .sidebar .sidebar-brand img.logo:last-child{
        visibility: hidden;
    }
    
    .sidebar .sidebar-brand img.mini:last-child{
        visibility: visible;
    } */
    
    .main-content{
        margin-left:70px;
        margin-top: 70px;
    }
    
    .main-content header{
        width: calc(100% - 70px);
        left: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .logo {
        width: 100px;
    }

    .sidebar {
        left:-100px !important;
        top:70px;
    }

    header h2 {
        display: flex;
        align-items: center;
        padding-top:0;
    }
    

    header h2 label {
        display: inline-block;
        padding-right: 0rem;
        padding-top: 0.2rem;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        color: #00163A;
        display: flex;
        align-items: center;
        justify-content: center !important;
    }

    header h2 span {
        text-align: center;
        padding-right: 0rem;
    }

    header h2 {
        font-size: 1.1rem;
    }
    .main-content {
        width: 100%;
        margin-left: 0;
    }

    header {
        width: 100% !important;
        left: 0 !important;
        padding-right: 1.50rem;
        padding-left: 0.5rem;
    }

    #nav-toggle:checked + .sidebar {
        left: 0 !important;
        width: 200px;
        z-index: 10;
    }

    #nav-toggle:checked + .sidebar .sidebar-menu img { 
        margin: auto 0;
    }
    
    #nav-toggle:checked + .sidebar li a{
        padding-left: 1rem;
    }
    
    #nav-toggle:checked + .sidebar li  span{
        padding-right: 1rem;
    }
    
    #nav-toggle:checked + .sidebar li a span:last-child{
        display: inline;
    }

    #nav-toggle:checked + .sidebar .sidebar-brand{
        padding: 0rem 0rem 0rem 0.5rem;
    }
    
    /* #nav-toggle:checked + .sidebar .sidebar-brand img.logo:last-child{
        visibility: hidden;
        display: none;
    }
    
    #nav-toggle:checked + .sidebar .sidebar-brand img.mini:last-child{
        visibility: hidden;
        display: none;
    } */
    
    #nav-toggle:checked ~ .main-content {
        margin-left: 0rem;
    }
}