@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

div.ant-form-item {
  margin-bottom: .5rem !important;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  transition: margin-left 300ms;
  /* margin-left: 250px; */
  min-height: 100vh;
  height: 100vh;
}

header {
  display: flex;
  justify-content: end;
  padding: 1rem 1.5rem;
  position: fixed;
  /* left: 200px; */
  /* width: calc(100% - 250px); */
  width: 100%;
  top: 0;
  z-index: 5;
  transition: left 300ms;
  background: #001529;
}

.logo {
  width: 50px;
  height: 50px;
  margin: auto;
}

.brand-logo {
  padding: 0.2rem;
}

.ant-layout-sider {
  z-index: 10;
}

.anticon svg {
  display: flex !important;
}

main {
  padding: 5rem 1.5rem 1.5rem;
  max-height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
}

.inner_shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(0, 0, 0, 0.05);
}

.side {
  max-height: calc(100vh);
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 20px;
  z-index: 5;
}

.side-parcel {
  max-height: calc(100vh);
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #d2d2d2;
  background-color: #fff;
  z-index: 100;
}

.header_popup {
  left: 82%;
  top: 80%;
  position: absolute;
  padding: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(0, 0, 0, 0.05);
}

/* .status_popup {
  left: 78%; 
  position: absolute;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), inset 0px 2px 2px rgba(0, 0, 0, 0.05);
}  */

input[type=checkbox]:checked {
  background-color: #191919 !important;
  width: 15px !important;
  height: 15px !important;
  /* margin: 5px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 2px solid #191919;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}

input[type=checkbox] {
  width: 15px !important;
  height: 15px !important;
  margin: -2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 2px solid #191919;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}


.react-datepicker__triangle {
  left: -15px !important;
}

.ant-form-vertical .ant-form-item {
  padding-top: 0.5rem;
}

.span {
  padding: 0 0 8px;
}

.date-form {
  margin: 1rem 0;
}

.ant-menu-title-content .anticon svg {
  display: flex;
  justify-items: center;
}

.map-container {
  width: 100%;
  height: 100%;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

@media (max-width: 600px) {
  .ant-picker-panels > *:last-child {
    display: none
  }

  .ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-header-next-btn {
    visibility: visible !important;
  }
}